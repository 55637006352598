.content-loader-total{
  height: 100vh;
  width: 100%;
  position: fixed;
  background: white;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  z-index: 2;
}

.content-loader-total img{
  width: 5rem;
}
